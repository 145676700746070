import axios from "axios";

export default {
    async getAziendaSettings() {
        const data = new FormData();
        data.append("limit", "0");

        const response = await axios.post(`rest/v1/search/settings`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response;
    },
};
